import {
  SearchDropdown,
  SearchDropdownAPI,
  Typography,
} from "@cfa/react-components";
import { useDelegateContext } from "../../context/DelegateContext";
import { retrieveAuthUserCfaId } from "../../utils/helpers/AuthHelper";
import { getOktaAccessToken } from "../../utils/LocalStorage";
import { useRef } from "react";
import DelegateUser from "../../interfaces/DelegateUser";
import { useGetAllStaff } from "../../hooks/api/admin/useGetAllStaff";
import styles from "./AdminDelegateSelectDropdown.module.scss";

const AdminDelegateSelectDropDown: React.FC = () => {
  const { loggedInUser, selectedUser, setSelectedUser } = useDelegateContext();
  const { data: allStaff } = useGetAllStaff();
  const adminSearchDropdownRef = useRef<SearchDropdownAPI>(null);

  const resetUser = () => (loggedInUser ? setSelectedUser(loggedInUser) : null);

  const onSearch = async (query: string, offset: number): Promise<any> => {
    const results = allStaff?.filter((item) =>
      `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`.includes(
        query.toLowerCase()
      )
    );
    return {
      canLoadMore: false,
      results: results,
    };
  };

  return allStaff && allStaff.length > 1 ? (
    <div className={styles.dropdownContainer}>
      <button
        className={styles.transparentClearButton}
        onClick={resetUser}
      ></button>
      <SearchDropdown
        ref={adminSearchDropdownRef}
        data-testid={"admin-delegate-select-dropdown"}
        value={selectedUser}
        renderOption={(option: DelegateUser) => {
          return {
            id: option.personId,
            rendered: (
              <Typography>
                {`${option?.firstName} ${option?.lastName}`}
                {retrieveAuthUserCfaId(getOktaAccessToken()) === option?.cfaId
                  ? " (Me)"
                  : ""}
              </Typography>
            ),
            textValue: option.firstName,
          };
        }}
        renderNoResults={(user) => {
          return <Typography>No Results</Typography>;
        }}
        onSearch={onSearch}
        onChange={(e) => e && setSelectedUser(e)}
        compact={true}
        label="Viewing as"
        placeholder="Search Staff"
      />
    </div>
  ) : (
    <></>
  );
};
export default AdminDelegateSelectDropDown;
