import { FC } from "react";
import styles from "./TripRows.module.scss";
import { TripDay } from "@cfacorp/business-travel-api-client";
import { Dropdown, Typography } from "@cfa/react-components";
import LocationSearchDropdown from "../LocationSearchDropdown/LocationSearchDropdown";
import { formatRowDate, workOptions } from "../../utils/tripDetailsHelper";
import {
  Control,
  Controller,
  useFieldArray,
  UseFormWatch,
} from "react-hook-form";
import { DayField, Location, Trip } from "../../utils/tripDaySchemas";

interface TripRowsProps {
  tripDays: DayField[];
  control: Control<Trip>;
  watch: UseFormWatch<Trip>;
  onLocationBulkChange: (location: Location) => void;
  isReadOnly: boolean;
}

const TripRows: FC<TripRowsProps> = ({
  tripDays,
  control,
  watch,
  onLocationBulkChange,
  isReadOnly,
}) => {
  const isDisabled =
    isReadOnly ||
    tripDays?.some((day) => day.tripStatus === TripDay.tripStatus.PROCESSED) ||
    false;

  const handleLocationChange = (location: Location) => {
    if (watch("tripDays")?.every((tripDay) => !tripDay.location)) {
      onLocationBulkChange(location);
    }
  };
  return (
    <>
      {tripDays?.map((day, index) => {
        return (
          <div className={styles.rowContainer} key={day.personId + day.date}>
            <Typography
              variant="body1"
              fontWeight="bold"
              className={`${styles.dateText} ${index === 0 ? styles.index0Margin : ""}`}
            >
              {formatRowDate(day.date)}
            </Typography>
            <div className={styles.dropdownContainer}>
              <Controller
                name={`tripDays.${index}.location`}
                control={control}
                render={({ field }) => (
                  <LocationSearchDropdown
                    disabled={isDisabled}
                    value={field.value}
                    hasLocationError={!field.value}
                    onChange={(location) => {
                      handleLocationChange(location ?? null);
                      field.onChange(location ?? null);
                    }}
                    label={index === 0 ? "Location" : ""}
                  />
                )}
              />
              <Controller
                name={`tripDays.${index}.workType`}
                control={control}
                render={({ field }) => {
                  return (
                    <Dropdown
                      disabled={isDisabled}
                      getOptionId={(option) =>
                        `${option?.dayType}-${option?.dayReason}`
                      }
                      options={workOptions}
                      value={field.value}
                      onChange={(option) => {
                        field.onChange(option);
                      }}
                      label={index === 0 ? "Was this a working day?" : ""}
                      renderOption={(option) => option?.label || ""}
                    />
                  );
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TripRows;
