import { businessTravelService } from "../../../utils/API";
import { useQuery } from "@tanstack/react-query";
import { formatUsers } from "../../../utils/helpers/formatUsers";

function useGetAllStaff() {
  const getAllStaff = async () => {
    const response = await businessTravelService.getAllStaffForAdminUser();
    return formatUsers(response);
  };

  return useQuery({
    queryKey: ["staff"],
    queryFn: getAllStaff,
    staleTime: 86400000,
  });
}
export { useGetAllStaff };
