import { Person } from "@cfacorp/business-travel-api-client";
import DelegateUser from "../../interfaces/DelegateUser";
import { getFirstName, getLastName } from "../../utils/helpers/PersonHelper";

export const formatUsers = (people: Person[]): DelegateUser[] => {
  return people.map((person) => {
    const user: DelegateUser = {
      cfaId: person.cfaId || "",
      personId: person.personId || "",
      firstName: getFirstName(person),
      lastName: getLastName(person),
    };
    return user;
  });
};
