import {
  useState,
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import DelegateUser from "../interfaces/DelegateUser";
import { useGetUserDelegates } from "../hooks/api/delegates/useGetUserDelegates";
import { retrieveAuthUserCfaId, isAdmin } from "../utils/helpers/AuthHelper";
import { getOktaAccessToken } from "../utils/LocalStorage";

type DelegateContextProp = {
  loggedInUser: DelegateUser | undefined;
  delegateUsers: DelegateUser[] | undefined;
  selectedUser: DelegateUser | undefined;
  isLoggedInUser: boolean;
  isDelegate: boolean;
  isLoading: boolean;
  isError: boolean;
  setSelectedUser: (delegateUser: DelegateUser) => void;
  isAdminUserViewingNonDelegate: boolean;
};

type DelegateProviderProp = { children: ReactNode };
const DelegateContext = createContext<DelegateContextProp | undefined>(
  undefined
);

export const DelegateProvider: React.FC<DelegateProviderProp> = ({
  children,
}) => {
  const { data: delegateUsers, isLoading, isError } = useGetUserDelegates();
  const [selectedUser, setSelectedUser] = useState(
    delegateUsers ? delegateUsers[0] : undefined
  );

  const isDelegate = useMemo(() => {
    const matchingDelegatesToSelectedUser =
      selectedUser &&
      delegateUsers?.filter(
        (e) =>
          e.cfaId === selectedUser?.cfaId &&
          selectedUser.cfaId !== retrieveAuthUserCfaId(getOktaAccessToken())
      );

    return matchingDelegatesToSelectedUser
      ? matchingDelegatesToSelectedUser.length > 0
      : false;
  }, [selectedUser]);

  const isLoggedInUser = useMemo(() => {
    return selectedUser
      ? selectedUser.cfaId === retrieveAuthUserCfaId(getOktaAccessToken())
      : false;
  }, [selectedUser]);

  const loggedInUser = useMemo(() => {
    const currentUserCfaId = retrieveAuthUserCfaId(getOktaAccessToken());
    return delegateUsers?.find((user) => user.cfaId === currentUserCfaId);
  }, [delegateUsers]);

  const isAdminUserViewingNonDelegate =
    isAdmin() && !isDelegate && !isLoggedInUser;

  useEffect(() => {
    if (!selectedUser && delegateUsers && delegateUsers.length > 0) {
      const currentUserCfaId = retrieveAuthUserCfaId(getOktaAccessToken());
      const currentUser = delegateUsers.find(
        (user) => user.cfaId === currentUserCfaId
      );
      setSelectedUser(currentUser);
    }
  }, [delegateUsers, selectedUser, setSelectedUser]);

  return (
    <DelegateContext.Provider
      value={{
        loggedInUser,
        delegateUsers,
        selectedUser,
        isLoggedInUser,
        isDelegate,
        isLoading,
        isError,
        setSelectedUser,
        isAdminUserViewingNonDelegate,
      }}
    >
      {children}
    </DelegateContext.Provider>
  );
};

export const useDelegateContext = (): DelegateContextProp => {
  const context = useContext(DelegateContext);
  if (!context) {
    throw new Error(
      "Delegate Context is undefined, make sure useDelegateContext is being used within a react component wrapped in DelegateProvider"
    );
  }
  return context;
};
