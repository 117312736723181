import { FC, useState } from "react";
import { Typography, Tabs, TabItem } from "@cfa/react-components";
import styles from "./History.module.scss";
import DelegateSelectDropDown from "../../components/DelegateSelect/DelegateSelectDropDown";
import Thresholds from "./components/Thresholds/Thresholds";
import ProcessedTrips from "./components/ProcessedTrips/ProcessedTrips";
import { isAdmin } from "../../utils/helpers/AuthHelper";
import AdminDelegateSelectDropDown from "../../components/AdminDelegateSelect/AdminDelegateSelectDropDown";

const History: FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>("processed");

  return (
    <div className={styles.historyContainer}>
      <div className={styles.subHeaderContainer}>
        <div className={styles.subHeaderLeft}>
          <Typography
            className={styles.subHeaderText}
            variant={"h3"}
            color={"secondary"}
          >
            View
          </Typography>
          <Tabs
            data-testid={"tabs-control"}
            onChange={setSelectedTab}
            activeItemKey={selectedTab}
          >
            <TabItem
              itemKey={"processed"}
              data-testid={"processed-trip-tab-item"}
            >
              Processed Trips
            </TabItem>
            <TabItem itemKey={"thresholds"} data-testid={"thresholds-tab-item"}>
              Thresholds
            </TabItem>
          </Tabs>
        </div>
        <div className={styles.subHeaderRight}>
          <div className={styles.headerRightContainer}>
            {isAdmin() ? (
              <AdminDelegateSelectDropDown />
            ) : (
              <DelegateSelectDropDown />
            )}
          </div>
        </div>
      </div>
      {selectedTab === "processed" && <ProcessedTrips />}
      {selectedTab === "thresholds" && <Thresholds />}
    </div>
  );
};

export default History;
