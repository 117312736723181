import { BannerMessage, Link } from "@cfa/react-components";
import styles from "./NextBannerMessage.module.scss";

export const NextBannerMessage = () => {
  return (
    <BannerMessage severity="info" show>
      As a reminder, NEXT is not a conference. For more information about
      validating travel for NEXT 2025, please view our page on @Chick-fil-A{" "}
      <Link
        variant="inline"
        href="https://portal.cfahome.com/en/corporate-resources/corporate-travel/business-travel-validation-tool/#TopicConten-BTVT-Considerations-for-NEXT-2025"
        target="_blank"
        className={styles.bannerLink}
      >
        here
      </Link>
      .
    </BannerMessage>
  );
};
