import { useDelegateContext } from "../../context/DelegateContext";
import { BannerMessage } from "@cfa/react-components";

export const AdminBanner = () => {
  const {
    isLoading: isDelegatesLoading,
    selectedUser,
    isAdminUserViewingNonDelegate,
  } = useDelegateContext();

  return !isDelegatesLoading && isAdminUserViewingNonDelegate ? (
    <BannerMessage severity="info" show>
      You are viewing trips for {selectedUser?.firstName}{" "}
      {selectedUser?.lastName} in read-only mode. Become a delegate of this user
      to edit trip details.
    </BannerMessage>
  ) : null;
};
