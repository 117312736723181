import { FC } from "react";
import { Typography } from "@cfa/react-components";
import styles from "./TripHeading.module.scss";
import { useDelegateContext } from "../../../../context/DelegateContext";
import { DayField } from "../../utils/tripDaySchemas";

interface TripHeadingProps {
  tripDays?: DayField[];
  isNewTrip: boolean;
}

const TripHeading: FC<TripHeadingProps> = ({ tripDays, isNewTrip = false }) => {
  const getUniqueLocations = (tripDays: DayField[]) =>
    [
      ...new Set(
        tripDays
          .map((tripDay) =>
            tripDay.location?.countryCode === "US" ||
            tripDay.location?.countryCode === "CA"
              ? tripDay.location.state
              : tripDay.location?.country
          )
          .filter((location) => location !== undefined)
      ),
    ] as string[];

  const states = isNewTrip
    ? "new"
    : tripDays && tripDays?.length > 0
      ? getUniqueLocations(tripDays)
      : "new";
  const locationText =
    states === "new"
      ? ""
      : states.length > 2
        ? "Your trip"
        : states.length > 1
          ? states.slice(0, -1).join(", ") + " and " + states[states.length - 1]
          : states[0];
  const { selectedUser, isLoggedInUser } = useDelegateContext();
  const headingText = !isLoggedInUser
    ? states === "new"
      ? `Tell us about ${selectedUser?.firstName}'s new trip`
      : states.length > 2
        ? `${selectedUser?.firstName}'s trip`
        : `${selectedUser?.firstName}'s trip to ${locationText}`
    : states === "new"
      ? "Tell us about your new trip"
      : states.length > 2
        ? "Your trip"
        : `Your trip to ${locationText}`;

  return (
    <Typography
      variant="h2"
      className={isLoggedInUser ? styles.heading : styles.delegateHeading}
    >
      {headingText}
    </Typography>
  );
};

export default TripHeading;
