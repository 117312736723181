import { FC } from "react";
import { Typography } from "@cfa/react-components";
import styles from "./ProcessedTrips.module.scss";
import ProcessedTripsWidget from "./ProcessedTripsWidget/ProcessedTripsWidget";
import FilterWidget from "./FilterWidget/FilterWidget";
import { useProcessedTripDaysQuery } from "../../../../hooks/api/trips/useProcessedTripDaysQuery";
import { useFilter } from "../../hooks/useFilter";
import HelpWidget from "../../../../components/HelpWidget/HelpWidget";
import { useDelegateContext } from "../../../../context/DelegateContext";
import { filterTrips } from "../../utils/filterUtils";

const ProcessedTrips: FC = () => {
  const { state: filterState, dispatch } = useFilter(false);
  const { selectedUser } = useDelegateContext();
  const { data: tripDays, isLoading } = useProcessedTripDaysQuery(
    selectedUser?.personId
  );
  const filteredTrips = filterTrips(tripDays || [], filterState);

  return (
    <div
      data-testid={"processed-trip-div"}
      className={styles.processedTripsContainer}
    >
      <ProcessedTripsWidget tripDays={filteredTrips} isLoading={isLoading} />
      <div className={styles.rightWidgetContainer}>
        <FilterWidget
          trips={
            tripDays?.filter(
              (trip) => trip.personId === selectedUser?.personId
            ) || []
          }
          filterState={filterState}
          dispatchFilter={dispatch}
        />
        <HelpWidget
          additionalText={
            <>
              <Typography>
                Processed trips are trips that were due for validation in a
                prior validation window.
              </Typography>
              <br />
              <Typography>
                Because of the two-month lag between the trip occurring and
                payroll processing, a tax year includes trips that occurred from
                November 1 of the prior year to October 31 of the current year.
              </Typography>
            </>
          }
        />
      </div>
    </div>
  );
};

export default ProcessedTrips;
