import { FC, useState } from "react";
import styles from "./TripDetails.module.scss";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { CrossMark } from "@cfa/brand-icons";
import { BannerMessage, Icon } from "@cfa/react-components";
import { useDelegateContext } from "../../context/DelegateContext";
import { useTripsDaysQuery } from "../../hooks/api/trips/useTripDaysQuery";
import Loading from "../../components/Loading/Loading";
import TripRows from "./components/TripRows/TripRows";
import TripHeading from "./components/TripHeading/TripHeading";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import TripDetailsButtons from "./components/TripDetailButtons/TripDetailsButtons";
import { TripDay } from "@cfacorp/business-travel-api-client";
import tripStatus = TripDay.tripStatus;
import DatePickers from "./components/DatePickers/DatePickers";
import {
  convertTripDay,
  editTripDaysByDateRange,
  getAllTripsForSelectedUser,
  isOverlappingTrip,
} from "./utils/tripDetailsHelper";
import { Trip, tripDaysSchema } from "./utils/tripDaySchemas";
import { usePostTripDaysMutation } from "../../hooks/api/trips/usePostTripDaysMutation";
import { CreationReason } from "../../components/AddNewTripButton/components/CreationReasonModal/CreationReasonModal";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import dayType = TripDay.dayType;

export interface TripDetailsParams {
  tripId: string;
}

const TripDetails: FC = () => {
  const history = useHistory();
  const {
    isLoggedInUser,
    selectedUser,
    isAdminUserViewingNonDelegate: isReadOnly,
  } = useDelegateContext();
  const location = useLocation<{ creationReason?: CreationReason }>();
  const { tripId } = useParams<TripDetailsParams>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: allTrips, isLoading } = useTripsDaysQuery(
    selectedUser?.personId,
    (tripDays) => {
      remove();
      return tripDays
        .filter((day) => day.tripId === tripId)
        .map((day) => {
          return convertTripDay(day);
        })
        .forEach((day) => append(day));
    }
  );

  const { control, handleSubmit, watch, setValue } = useForm<Trip>({
    resolver: zodResolver(tripDaysSchema),
    defaultValues: { tripDays: [] },
  });
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: "tripDays",
  });
  const creationReason = location.state?.creationReason as
    | CreationReason
    | undefined;

  const mutation = usePostTripDaysMutation(!!creationReason);
  const watchedTripDays = watch("tripDays");

  const isSubmitDisabled =
    watchedTripDays.some((day) => !day.location?.countryCode) ||
    isOverlappingTrip(watchedTripDays, allTrips || []);

  const isHistoryView =
    fields?.length > 0 && fields[0]?.tripStatus !== tripStatus.PROCESSED;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const navigateToHome = () => {
    history.replace("/");
  };

  const navigateBack = () => {
    history.goBack();
  };

  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    if (!startDate || !endDate || !selectedUser) return;
    const updatedDays = editTripDaysByDateRange(
      startDate,
      endDate,
      watchedTripDays,
      selectedUser,
      creationReason
    );
    replace(updatedDays);
  };

  const cancelTripDays = (data: Trip) => {
    return {
      tripDays: data.tripDays.map((day) => {
        return {
          ...day,
          workType: {
            dayType: dayType.NON_WORK,
            dayReason: "cancelled",
            label: "No, canceled",
          },
        };
      }),
    };
  };

  const onSubmit = (data: Trip) => {
    if (!data?.tripDays) {
      console.warn("Trip was empty");
      return;
    }
    if (!isSubmitDisabled) {
      mutation.mutate(data, { onSuccess: navigateToHome });
    }
  };

  const onBack = () => {
    if (isHistoryView && !isReadOnly) {
      toggleModal();
    } else {
      navigateBack();
    }
  };

  return (
    <div
      className={`${styles.tripContainer} ${isLoggedInUser ? "" : styles.delegateBackground}`}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={styles.crossMarkContainer}>
            <Icon icon={CrossMark} size="lg" onClick={onBack} />
          </div>
          <TripHeading tripDays={fields} isNewTrip={!tripId} />
          {isOverlappingTrip(watchedTripDays, allTrips || []) && (
            <BannerMessage severity={"error"} show>
              A trip may not contain days from a previously validated trip
            </BannerMessage>
          )}
          {mutation.isError && (
            <BannerMessage severity={"error"} show>
              Uh oh! Something went wrong. Please try again.
            </BannerMessage>
          )}
          <DatePickers
            tripDays={fields}
            allTrips={getAllTripsForSelectedUser(
              allTrips,
              tripId,
              selectedUser
            )}
            onDateChange={handleDateChange}
            isReadOnly={isReadOnly}
          />
          <TripRows
            tripDays={fields}
            control={control}
            watch={watch}
            onLocationBulkChange={(location) => {
              fields.forEach((_, index) => {
                setValue(`tripDays.${index}.location`, location);
              });
            }}
            isReadOnly={isReadOnly}
          />
          <TripDetailsButtons
            onBack={onBack}
            onLooksGood={handleSubmit(onSubmit)}
            onDidntGo={handleSubmit((data) => {
              onSubmit(cancelTripDays(data));
            })}
            submitDisabled={isSubmitDisabled}
            isReadOnly={isReadOnly}
            tripStatus={
              tripId && fields?.length > 0 ? fields[0].tripStatus : undefined
            }
          />
          <ConfirmationModal
            onConfirm={navigateToHome}
            onBack={toggleModal}
            isModalOpen={isModalOpen}
          />
        </>
      )}
    </div>
  );
};

export default TripDetails;
