import { Location } from "../utils/tripDaySchemas";

export const LOCATIONS: Location[] = [
  {
    countryCode: "US",
    country: "United States",
    stateCode: "AL",
    state: "Alabama",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "AK",
    state: "Alaska",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "AZ",
    state: "Arizona",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "AR",
    state: "Arkansas",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "CA",
    state: "California",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "CO",
    state: "Colorado",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "CT",
    state: "Connecticut",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "DE",
    state: "Delaware",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "FL",
    state: "Florida",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "GA",
    state: "Georgia",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "HI",
    state: "Hawaii",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "ID",
    state: "Idaho",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "IL",
    state: "Illinois",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "IN",
    state: "Indiana",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "IA",
    state: "Iowa",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "KS",
    state: "Kansas",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "KY",
    state: "Kentucky",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "LA",
    state: "Louisiana",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "ME",
    state: "Maine",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "MD",
    state: "Maryland",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "MA",
    state: "Massachusetts",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "MI",
    state: "Michigan",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "MN",
    state: "Minnesota",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "MS",
    state: "Mississippi",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "MO",
    state: "Missouri",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "MT",
    state: "Montana",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "NE",
    state: "Nebraska",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "NV",
    state: "Nevada",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "NH",
    state: "New Hampshire",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "NJ",
    state: "New Jersey",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "NM",
    state: "New Mexico",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "NY",
    state: "New York",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "NC",
    state: "North Carolina",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "ND",
    state: "North Dakota",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "OH",
    state: "Ohio",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "OK",
    state: "Oklahoma",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "OR",
    state: "Oregon",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "PA",
    state: "Pennsylvania",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "RI",
    state: "Rhode Island",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "SC",
    state: "South Carolina",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "SD",
    state: "South Dakota",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "TN",
    state: "Tennessee",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "TX",
    state: "Texas",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "UT",
    state: "Utah",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "VT",
    state: "Vermont",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "VA",
    state: "Virginia",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "WA",
    state: "Washington",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "WV",
    state: "West Virginia",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "WI",
    state: "Wisconsin",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "WY",
    state: "Wyoming",
  },
  {
    countryCode: "US",
    country: "United States",
    stateCode: "DC",
    state: "District of Columbia",
    alias: "Washington, D.C.",
  },
  { countryCode: "CA", country: "Canada", stateCode: "AB", state: "Alberta" },
  {
    countryCode: "CA",
    country: "Canada",
    stateCode: "BC",
    state: "British Columbia",
  },
  { countryCode: "CA", country: "Canada", stateCode: "MB", state: "Manitoba" },
  {
    countryCode: "CA",
    country: "Canada",
    stateCode: "NB",
    state: "New Brunswick",
  },
  {
    countryCode: "CA",
    country: "Canada",
    stateCode: "NL",
    state: "Newfoundland and Labrador",
  },
  {
    countryCode: "CA",
    country: "Canada",
    stateCode: "NS",
    state: "Nova Scotia",
  },
  { countryCode: "CA", country: "Canada", stateCode: "ON", state: "Ontario" },
  {
    countryCode: "CA",
    country: "Canada",
    stateCode: "PE",
    state: "Prince Edward Island",
  },
  { countryCode: "CA", country: "Canada", stateCode: "QC", state: "Quebec" },
  {
    countryCode: "CA",
    country: "Canada",
    stateCode: "SK",
    state: "Saskatchewan",
  },
  {
    countryCode: "CA",
    country: "Canada",
    stateCode: "NT",
    state: "Northwest Territories",
  },
  { countryCode: "CA", country: "Canada", stateCode: "NU", state: "Nunavut" },
  { countryCode: "CA", country: "Canada", stateCode: "YT", state: "Yukon" },
  { countryCode: "PR", country: "Puerto Rico", stateCode: "", state: "" },
  { countryCode: "UK", country: "United Kingdom", stateCode: "", state: "" },
  { countryCode: "SG", country: "Singapore", stateCode: "", state: "" },
  { countryCode: "CR", country: "Costa Rica", stateCode: "", state: "" },
  { countryCode: "MX", country: "Mexico", stateCode: "", state: "" },
  { countryCode: "FR", country: "France", stateCode: "", state: "" },
  { countryCode: "DE", country: "Germany", stateCode: "", state: "" },
  { countryCode: "JP", country: "Japan", stateCode: "", state: "" },
  { countryCode: "AU", country: "Australia", stateCode: "", state: "" },
  { countryCode: "NZ", country: "New Zealand", stateCode: "", state: "" },
  { countryCode: "ZA", country: "South Africa", stateCode: "", state: "" },
  { countryCode: "KR", country: "South Korea", stateCode: "", state: "" },
  { countryCode: "CN", country: "China", stateCode: "", state: "" },
  { countryCode: "BR", country: "Brazil", stateCode: "", state: "" },
  { countryCode: "IN", country: "India", stateCode: "", state: "" },
  { countryCode: "RU", country: "Russia", stateCode: "", state: "" },
  { countryCode: "IT", country: "Italy", stateCode: "", state: "" },
  { countryCode: "ES", country: "Spain", stateCode: "", state: "" },
  { countryCode: "NL", country: "Netherlands", stateCode: "", state: "" },
  { countryCode: "SE", country: "Sweden", stateCode: "", state: "" },
  { countryCode: "CH", country: "Switzerland", stateCode: "", state: "" },
  { countryCode: "AT", country: "Austria", stateCode: "", state: "" },
  { countryCode: "BE", country: "Belgium", stateCode: "", state: "" },
  { countryCode: "DK", country: "Denmark", stateCode: "", state: "" },
  { countryCode: "NO", country: "Norway", stateCode: "", state: "" },
  { countryCode: "FI", country: "Finland", stateCode: "", state: "" },
  { countryCode: "IE", country: "Ireland", stateCode: "", state: "" },
  { countryCode: "PT", country: "Portugal", stateCode: "", state: "" },
  { countryCode: "GR", country: "Greece", stateCode: "", state: "" },
  { countryCode: "PL", country: "Poland", stateCode: "", state: "" },
  { countryCode: "CZ", country: "Czech Republic", stateCode: "", state: "" },
  { countryCode: "HU", country: "Hungary", stateCode: "", state: "" },
  { countryCode: "TR", country: "Turkey", stateCode: "", state: "" },
];
