import { Card, Typography } from "@cfa/react-components";
import styles from "./TripCard.module.scss";
import { FC } from "react";
import { TripDay } from "@cfacorp/business-travel-api-client";
import {
  locationStringOutput,
  tripDateOutput,
  tripStatusOutput,
} from "../../../../utils/helpers/tripCardHelper";
import { useHistory } from "react-router-dom";
import { useDelegateContext } from "../../../../context/DelegateContext";
import LocationText from "../../../../components/LocationText/LocationText";

type TripCardProps = {
  tripDays: TripDay[];
  tripId?: string;
  isClickable?: boolean;
};

const TripCard: FC<TripCardProps> = ({
  tripDays,
  tripId,
  isClickable = true,
}: TripCardProps) => {
  const locationOutput = locationStringOutput(tripDays);
  const history = useHistory();
  const { isLoggedInUser } = useDelegateContext();

  const handleCardClick = () => {
    if (!tripId) {
      console.warn("No tripId Found");
      return;
    }

    if (isClickable) {
      history.push(`/trip/${tripId}`);
    }
  };

  return (
    <Card
      elevation={2}
      className={
        isLoggedInUser ? styles.cardContainer : styles.delegateCardContainer
      }
      onClick={isClickable ? handleCardClick : undefined}
      style={{ cursor: isClickable ? "pointer" : "default" }}
    >
      <img
        src={locationOutput.icon}
        alt="location icon"
        className={styles.iconContainer}
      ></img>
      <div className={styles.textContainer}>
        <LocationText tripDays={tripDays} className={styles.textRow} />
        <Typography variant="caption1" className={styles.textRow}>
          {tripDateOutput(tripDays).formattedRange}
        </Typography>
        <Typography
          variant="caption1"
          className={tripStatusOutput(tripDays[0].tripStatus).statusTextColor}
        >
          {tripStatusOutput(tripDays[0].tripStatus).statusCardText}
        </Typography>
      </div>
    </Card>
  );
};

export default TripCard;
