import { useQuery } from "@tanstack/react-query";
import { businessTravelService } from "../../../utils/API";
import { TripDay } from "@cfacorp/business-travel-api-client";

const useProcessedTripDaysQuery = (personId: string | undefined) => {
  const fetchTrips = async (): Promise<TripDay[]> => {
    const response = await businessTravelService.getTripDays({
      status: TripDay.tripStatus.PROCESSED,
      personId: personId,
    });
    return response.tripDays || [];
  };

  return useQuery({
    queryKey: [personId, "ProcessedTrips"],
    queryFn: fetchTrips,
  });
};

export { useProcessedTripDaysQuery };
