import { useQuery } from "@tanstack/react-query";
import { LocationDays } from "@cfacorp/business-travel-api-client";
import { businessTravelService } from "../../../utils/API";

function useGetUserTravelLocations(
  personId: string | undefined,
  taxYear: string
) {
  const getUserTravelLocations = async () => {
    if (!personId) {
      return [] as LocationDays[];
    }
    return businessTravelService.getLocationDays({
      personId: personId,
      taxYear: taxYear,
    });
  };

  return useQuery({
    queryKey: ["locations", personId, taxYear],
    queryFn: getUserTravelLocations,
    enabled: !!personId && !!taxYear,
  });
}

export { useGetUserTravelLocations };
