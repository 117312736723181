import { businessTravelService } from "../../../utils/API";
import { useQuery } from "@tanstack/react-query";
import { formatUsers } from "../../../utils/helpers/formatUsers";

function useGetUserDelegates() {
  const getUserDelegates = async () => {
    const response = await businessTravelService.getDelegatesForContextUser();
    return formatUsers(response);
  };

  return useQuery({
    queryKey: ["delegates"],
    queryFn: getUserDelegates,
    staleTime: 86400000,
  });
}
export { useGetUserDelegates };
