import { FC, useState } from "react";
import { Button, Tooltip } from "@cfa/react-components";
import styles from "./AddNewTripButton.module.scss";
import { useDelegateContext } from "../../context/DelegateContext";
import { useHistory } from "react-router-dom";
import CreationReasonModal, {
  CreationReason,
} from "./components/CreationReasonModal/CreationReasonModal";

interface AddNewTripButtonProps {
  className?: string;
}

const AddNewTripButton: FC<AddNewTripButtonProps> = ({ className }) => {
  const { isLoggedInUser, isAdminUserViewingNonDelegate } =
    useDelegateContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  const navigateToTrip = (reason: CreationReason) => {
    history.push({
      pathname: "/trip",
      state: { creationReason: reason },
    });
  };

  return (
    <>
      {isAdminUserViewingNonDelegate ? (
        <Tooltip content="You must be set as a delegate of this user to add a trip">
          <Button className={`${styles.isVisuallyDisabled} ${className}`}>
            Add new trip
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={() => setIsModalOpen(true)}
          className={`${isLoggedInUser ? "" : styles.delegateAddTripButton} ${className}`}
        >
          Add new trip
        </Button>
      )}
      <CreationReasonModal
        onCreate={(reason) => navigateToTrip(reason)}
        onBack={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
      />
    </>
  );
};

export default AddNewTripButton;
