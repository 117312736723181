import React, { useEffect, useState } from "react";
import { Typography, DatePicker } from "@cfa/react-components";
import styles from "./DatePicker.module.scss";
import { TripDay } from "@cfacorp/business-travel-api-client";
import tripStatus = TripDay.tripStatus;
import { tripStartAndEnd } from "../../../../utils/helpers/tripCardHelper";
import { getDisabledDates } from "../../utils/tripDetailsHelper";
import { useParams } from "react-router-dom";
import { TripDetailsParams } from "../../TripDetails";
import { DayField } from "../../utils/tripDaySchemas";

interface DatePickerProps {
  tripDays?: DayField[] | undefined;
  allTrips: TripDay[] | undefined;
  onDateChange: (startDate: Date | null, endDate: Date | null) => void;
  isReadOnly: boolean;
}

const DatePickers: React.FC<DatePickerProps> = ({
  tripDays,
  allTrips,
  onDateChange,
  isReadOnly,
}) => {
  const isDisabled =
    isReadOnly ||
    tripDays?.some((day) => day.tripStatus === tripStatus.PROCESSED);

  const [pickerStartDate, setPickerStartDate] = useState<Date | null>(null);
  const [pickerEndDate, setPickerEndDate] = useState<Date | null>(null);
  const [loadingDates, setLoadingDates] = useState(true);
  const { tripId } = useParams<TripDetailsParams>();

  const { startDate: defaultStartDate, endDate: defaultEndDate } =
    tripStartAndEnd(tripDays);

  useEffect(() => {
    if (defaultStartDate && defaultEndDate) {
      setPickerStartDate(defaultStartDate);
      setPickerEndDate(defaultEndDate);
      setLoadingDates(false);
    } else if (!tripId) {
      setLoadingDates(false);
    }
  }, [tripDays, tripId]);

  const handleStartDateChange = (newStartDate: Date | null) => {
    if (newStartDate?.toString() === "Invalid Date") return false;
    setPickerStartDate(newStartDate);
    if (pickerEndDate && newStartDate && newStartDate > pickerEndDate) {
      onDateChange(newStartDate, newStartDate);
    } else {
      onDateChange(newStartDate, pickerEndDate);
    }
  };

  const handleEndDateChange = (newEndDate: Date | null) => {
    if (newEndDate?.toString() === "Invalid Date") return false;
    setPickerEndDate(newEndDate);
    if (pickerStartDate && newEndDate && newEndDate < pickerStartDate) {
      onDateChange(newEndDate, newEndDate);
    } else {
      onDateChange(pickerStartDate, newEndDate);
    }
  };

  const isDateDisabled = (date: Date) => {
    if (date.toString() === "Invalid Date") return false;
    const referenceDate = new Date(defaultStartDate || new Date());
    return getDisabledDates(date, referenceDate, allTrips || []);
  };

  return (
    <div className={styles.dateRowContainer}>
      <Typography
        variant="body1"
        fontWeight="bold"
        className={styles.textContainer}
      >
        Dates:
      </Typography>

      {!loadingDates && (
        <div className={styles.datePickerContainer}>
          <DatePicker
            value={pickerStartDate}
            onChange={handleStartDateChange}
            disabled={isDisabled}
            disabledDates={isDateDisabled}
            placeholder="Select start date"
            data-testid="start-date-picker"
            label={"Start Date"}
          />
          <DatePicker
            value={pickerEndDate}
            onChange={handleEndDateChange}
            disabled={isDisabled || !pickerStartDate}
            disabledDates={isDateDisabled}
            placeholder="Select end date"
            data-testid="end-date-picker"
            label={"End Date"}
          />
        </div>
      )}
    </div>
  );
};

export default DatePickers;
