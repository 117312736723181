import { Card, Typography, Button } from "@cfa/react-components";
import { tripListsToDisplay } from "../../../../utils/helpers/tripCardHelper";
import styles from "./TripsWidget.module.scss";
import HomeHeader from "../HomeHeader/HomeHeader";
import TripCard from "../TripCard/TripCard";
import { useDelegateContext } from "../../../../context/DelegateContext";
import { isValidationWindowOpen } from "../../utils/validationWindowHelper";
import { useTripsDaysQuery } from "../../../../hooks/api/trips/useTripDaysQuery";
import Loading from "../../../../components/Loading/Loading";
import AddNewTripButton from "../../../../components/AddNewTripButton/AddNewTripButton";
import { FC } from "react";

const TripsWidget: FC = () => {
  const { selectedUser, isLoading: isDelegateLoading } = useDelegateContext();
  const { data: trips, isLoading } = useTripsDaysQuery(selectedUser?.personId);
  const { validatedTripsToDisplay, unvalidatedTripsToDisplay } =
    tripListsToDisplay(trips || [], selectedUser);

  return (
    <Card elevation={4} className={styles.tripsWidget}>
      {isLoading || isDelegateLoading ? (
        <Loading />
      ) : (
        <>
          <div className={styles.titleButtonContainer}>
            <HomeHeader
              unvalidatedTripsCount={unvalidatedTripsToDisplay.length}
              validatedTripsCount={validatedTripsToDisplay.length}
            />
            <AddNewTripButton />
          </div>

          <div className={styles.tripCardContainer}>
            {unvalidatedTripsToDisplay.length > 0 ? (
              unvalidatedTripsToDisplay.map((trip, index) => (
                <TripCard
                  key={index}
                  tripDays={trip}
                  tripId={trip[0]?.tripId || ""}
                  isClickable={true}
                />
              ))
            ) : !isValidationWindowOpen() ? (
              <Typography className={styles.subHeader}>
                Trips from previous cycles have been automatically processed and
                cannot be changed.
                <br />
                <br />
                Processed trips can be viewed under the History tab above for
                more details.
              </Typography>
            ) : validatedTripsToDisplay.length > 0 ? (
              <Typography className={styles.subHeader}>
                All trips for the current cycle have been validated and no
                further action is required.
              </Typography>
            ) : (
              <Typography className={styles.subHeader}>
                There are no trips currently due for validation.
                <br />
                <br />
                Processed trips can be viewed under the History tab above for
                more details.
              </Typography>
            )}
          </div>
          {validatedTripsToDisplay.length > 0 && (
            <>
              <Typography
                variant="body1"
                fontWeight="bold"
                className={styles.validatedTextContainer}
              >
                {isValidationWindowOpen() ? "Recently Validated" : "Pending"}
              </Typography>
              <div className={styles.tripCardContainer}>
                {validatedTripsToDisplay.map((trip, index) => (
                  <TripCard
                    key={index}
                    tripDays={trip}
                    tripId={trip[0]?.tripId || ""}
                    isClickable={true}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </Card>
  );
};
export default TripsWidget;
