import { TripDay } from "@cfacorp/business-travel-api-client";
import { tripStartAndEnd } from "../../../utils/helpers/tripCardHelper";
import DelegateUser from "../../../interfaces/DelegateUser";
import { v4 as uuid } from "uuid";
import { CreationReason } from "../../../components/AddNewTripButton/components/CreationReasonModal/CreationReasonModal";
import { DayField, WorkType } from "./tripDaySchemas";
import tripStatus = TripDay.tripStatus;
import dayType = TripDay.dayType;

export const workOptions: WorkType[] = [
  {
    label: "Yes",
    dayType: dayType.WORK,
    dayReason: "",
  },
  {
    label: "No, PTO or holiday",
    dayType: dayType.NON_WORK,
    dayReason: "pto",
  },
  {
    label: "No, conference",
    dayType: dayType.NON_WORK,
    dayReason: "conference",
  },
  {
    label: "No, travel day",
    dayType: dayType.NON_WORK,
    dayReason: "travel",
  },
  {
    label: "No, weekend",
    dayType: dayType.NON_WORK,
    dayReason: "saturday",
  },
  {
    label: "No, rescheduled",
    dayType: dayType.NON_WORK,
    dayReason: "rescheduled",
  },
  {
    label: "No, canceled",
    dayType: dayType.NON_WORK,
    dayReason: "cancelled",
  },
];

export const formatRowDate = (dateString: string) => {
  if (!dateString) {
    return "";
  }
  const [year, month, day] = dateString.split("-");
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  const options = { weekday: "long", month: "long", day: "numeric" } as const;
  return date.toLocaleDateString("en-US", options);
};

export const editTripDaysByDateRange = (
  startDate: Date,
  endDate: Date,
  tripDays: DayField[],
  selectedUser: DelegateUser,
  creationReason: CreationReason | undefined,
): DayField[] => {
  const newTripDays = tripDays ? [...tripDays] : [];
  if (!tripDays || tripDays.length === 0) {
    if (!creationReason) {
      console.error("Creation reason is required when creating new trip days");
      return newTripDays;
    }
    addNewTripDays(
      startDate,
      endDate,
      newTripDays,
      selectedUser,
      creationReason,
    );
    return newTripDays;
  }

  const { startDate: currentStartDate, endDate: currentEndDate } =
    tripStartAndEnd(tripDays);

  if (!currentStartDate || !currentEndDate) return [];

  adjustTripDays(startDate, currentStartDate, newTripDays, true);
  adjustTripDays(endDate, currentEndDate, newTripDays, false);

  return newTripDays;
};

const isWeekend = (dayOfWeek: string) =>
  dayOfWeek.toUpperCase() === "SATURDAY" ||
  dayOfWeek.toUpperCase() === "SUNDAY";

const adjustTripDays = (
  targetDate: Date,
  currentDate: Date,
  tripDays: DayField[],
  isStart: boolean,
): void => {
  while (isStart ? targetDate < currentDate : targetDate > currentDate) {
    currentDate.setDate(currentDate.getDate() - (isStart ? 1 : -1));
    const dayOfWeek = getDayOfWeek(currentDate);
    const newDay = {
      ...tripDays[isStart ? 0 : tripDays.length - 1],
      workType: {
        dayType: isWeekend(dayOfWeek) ? dayType.NON_WORK : dayType.WORK,
        dayReason: isWeekend(dayOfWeek) ? "saturday" : "",
        label: isWeekend(dayOfWeek) ? "No, weekend" : "Yes",
      },
      date: formatDate(currentDate),
      day: dayOfWeek,
    };
    if (isStart) {
      tripDays.unshift(newDay);
    } else {
      tripDays.push(newDay);
    }
  }

  while (isStart ? targetDate > currentDate : targetDate < currentDate) {
    if (isStart) {
      tripDays.shift();
    } else {
      tripDays.pop();
    }
    currentDate.setDate(currentDate.getDate() + (isStart ? 1 : -1));
  }
};

const addNewTripDays = (
  startDate: Date,
  endDate: Date,
  newTripDays: DayField[],
  selectedUser: DelegateUser,
  creationReason: CreationReason,
) => {
  const currentDate = new Date(startDate);
  const createTripId = uuid();
  while (currentDate <= endDate) {
    const dayOfWeek = getDayOfWeek(currentDate);
    newTripDays.push({
      personId: selectedUser.personId,
      cfaId: selectedUser.cfaId,
      date: formatDate(currentDate),
      day: dayOfWeek,
      workType: {
        dayType: isWeekend(dayOfWeek) ? dayType.NON_WORK : dayType.WORK,
        dayReason: isWeekend(dayOfWeek) ? "saturday" : "",
        label: isWeekend(dayOfWeek) ? "No, weekend" : "Yes",
      },
      location: null,
      tripStatus: tripStatus.OPEN,
      creationReason: creationReason,
      tripId: createTripId,
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }
};

const formatDate = (date: Date) => date.toISOString().split("T")[0];
const getDayOfWeek = (date: Date) =>
  date.toLocaleDateString("en-US", { weekday: "long" });

export const getDisabledDates = (
  date: Date,
  referenceDate: Date,
  overlapTripDays: TripDay[],
) => {
  const firstOfMonthBeforeStartDate = referenceDate;
  firstOfMonthBeforeStartDate.setMonth(
    firstOfMonthBeforeStartDate.getMonth() - 1,
    1,
  );
  firstOfMonthBeforeStartDate.setHours(0, 0, 0, 0);

  const endOfPreviousTaxYear = new Date(referenceDate.getFullYear() - 1, 9, 31); //THIS IS OCTOBER 31st.

  return (
    date > new Date() ||
    (overlapTripDays?.some(
      (trip) => trip.date === date.toISOString().split("T")[0],
    ) ??
      false) ||
    date < firstOfMonthBeforeStartDate ||
    date <= endOfPreviousTaxYear
  );
};

export const getAllTripsForSelectedUser = (
  allTrips: TripDay[] | undefined,
  tripId: string,
  selectedUser: DelegateUser | undefined,
) => {
  return (
    allTrips?.filter(
      (day) => day.tripId !== tripId && day.personId === selectedUser?.personId,
    ) || []
  );
};

export const isOverlappingTrip = (
  tripDays: DayField[],
  allTrips: TripDay[],
) => {
  if (tripDays?.length === 0) return false;

  const originalTrip = allTrips.filter(
    (trip) => trip.tripId === tripDays[0].tripId,
  );

  return tripDays.some((currentDay) =>
    allTrips.some(
      (existingDay) =>
        currentDay.personId === existingDay.personId &&
        existingDay.date === currentDay.date &&
        !originalTrip.some((trip) => trip.date === currentDay.date),
    ),
  );
};

export const convertTripDay = (day: TripDay): DayField => {
  return {
    personId: day.personId,
    date: day.date,
    tripId: day.tripId || "",
    day: day.day || "",
    cfaId: day.cfaId || "",
    creationReason: day.creationReason || "",
    createdDate: day.createdDate || "",
    firstName: day.firstName || "",
    lastName: day.lastName || "",
    expiration: day.expiration || "",
    tripStatus: day.tripStatus || tripStatus.OPEN,
    location: day.countryCode
      ? {
          countryCode: day.countryCode || "",
          stateCode: day.stateCode || "",
          country: day.country || "",
          state: day.state || "",
        }
      : null,
    workType: {
      dayType: day.dayType || dayType.WORK,
      dayReason: day.dayReason || "",
      label:
        workOptions.find((i) =>
          i.dayType === dayType.WORK
            ? i.dayType === day.dayType
            : i.dayReason === day.dayReason,
        )?.label || "Yes",
    },
  };
};
